import { RoleEnum } from 'plataforma-braille-common';
import {
    getBrailleDocument,
    getClosestEditorElement,
    getClosestElementRepresentation,
    getClosestTextNode,
    isEditorElementImageLayout,
    isEditorElementRepresentationParagraphBreak,
    isEditorPage,
    isMultipleSelection,
    isTinyCaret,
    scrollToCursorIfNeeded,
} from './core/EditorUtil';
import {
    fixPageTermination,
    getCurrentPage,
    getPages,
    goToPage,
    insertBlankPage,
    insertPageBreak,
    pullLinesFromNextPages,
    removeEmptyPage,
} from './core/PageManipulation';
import {
    getCaretLine,
    getCaretPosition,
    getLineCount,
    scanCaretPath,
    setCaretPosition,
} from './core/CaretPath';
import { isDebugEnabled } from './core/CoreModule';
import { uncachePage } from './core/Cache';
import { isEditorElementImage } from './core/editor-element/EditorElementImage';
import {
    isEditorElementAngle,
    isInsideEditorElementAngle,
} from './core/editor-element/EditorElementAngle';
import {
    isEditorElementNthRoot,
    isInsideEditorElementNthRoot,
} from './core/editor-element/EditorElementNthRoot';
import {
    isEditorElementLineSegment,
    isInsideEditorElementLineSegment,
} from './core/editor-element/EditorElementLineSegment';
import {
    isInsideAlignmentCenter,
    isInsideAlignmentRight,
} from './core/editor-element/EditorElementAlignment';
import { isInnerContextElement } from './core/EditorElements';
import {
    focusOnCurrentEditorElementHeading,
    getClosestEditorElementHeading,
    isEditorElementHeading,
    isInsideEditorElementHeading,
    removeCurrentEditorElementHeading,
} from './core/editor-element/EditorElementHeading';

export const ZERO_WIDTH_NB_CHAR = '\uFEFF'; // special char to do some trick with caret position
export const ZERO_WIDTH_SPACE_CHAR = '\u200B';

export class KeyboardModule {
    /**
     * @type {HTMLElement | null}
     */
    keyDownCurrentPage = null;
    /**
     * @type {number | null}
     */
    keyDownLineCount = null;
    firePageDataChangedOnKeyUp = false;

    /**
     * @param editor {EditorCustom}
     * @param readOnly {boolean}
     * @param isAdmin {boolean}
     * @param isEvaluator {boolean}
     * @param isModerator {boolean}
     * @param userDocumentRoles {RoleEnum[]}
     */
    constructor(
        editor,
        readOnly,
        isAdmin,
        isEvaluator,
        isModerator,
        userDocumentRoles,
    ) {
        this.editor = editor;
        this.readOnly = readOnly;
        this.isAdmin = isAdmin;
        this.isEvaluator = isEvaluator;
        this.isModerator = isModerator;
        this.userDocumentRoles = userDocumentRoles;
    }

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug('[KeyboardModule]', ...data);
        }
    }

    /**
     * @param e {KeyboardEvent}
     */
    enterPressed(e) {
        const self = this;
        function newPage() {
            self.debug('Blank page inserted');
            const newPage = insertBlankPage(self.editor);
            fixPageTermination(self.editor, newPage);
            self.editor.selection.setCursorLocation(newPage, 0);
            scrollToCursorIfNeeded(self.editor);
            self.firePageDataChangedOnKeyUp = true;
        }

        const brailleDocument = getBrailleDocument(this.editor);

        const selectedNode = this.editor.selection.getNode();
        if (e.shiftKey) {
            e.preventDefault();
            if (e.ctrlKey) {
                newPage();
            }
        } else if (
            isInsideEditorElementNthRoot(selectedNode) ||
            isInsideEditorElementLineSegment(selectedNode) ||
            isInsideEditorElementAngle(selectedNode)
        ) {
            e.preventDefault();
            this.debug('Enter prevented in unsupported element');
        } else if (e.ctrlKey) {
            this.debug('Page break');
            e.preventDefault();
            const currentPage = getCurrentPage(this.editor);
            const newPage = insertPageBreak(this.editor);
            currentPage.setAttribute('data-needs-update', 'true');
            newPage.setAttribute('data-needs-update', 'true');
            scrollToCursorIfNeeded(this.editor);
            self.firePageDataChangedOnKeyUp = true;
        } else if (brailleDocument.convertedToBraille) {
            const { line: currentLine, page: currentPage } = getCaretLine(
                this.editor,
            );
            // in last line of page
            // add two count a not inserted yet enter
            if (currentLine + 1 >= brailleDocument.brailleCellRowCount) {
                if (!currentPage.nextSibling) {
                    newPage();
                }
            }
            self.firePageDataChangedOnKeyUp = true;
        } else {
            self.firePageDataChangedOnKeyUp = true;
        }
    }

    /**
     * @param e {KeyboardEvent}
     */
    backspacePressed(e) {
        const caretPosition = getCaretPosition(this.editor);
        if (!caretPosition) return;
        const isPage = isEditorPage(caretPosition.contextElement);
        if (
            isPage &&
            removeEmptyPage(this.editor, caretPosition.contextElement)
        ) {
            e.preventDefault();
            this.debug('Empty page removed');
            return;
        } else if (isTinyCaret(caretPosition.contextElement)) {
            e.preventDefault();
            return;
        }
        if (!isPage) return;
        if (caretPosition.path.length === 0) {
            const previousPage = caretPosition.contextElement.previousSibling;
            if (!previousPage) {
                e.preventDefault();
                return;
            }
            setCaretPosition(
                this.editor,
                previousPage,
                scanCaretPath(previousPage),
            );
            // this fix a bug removing first char of a page
        } else if (caretPosition.path.length === 1) {
            e.preventDefault();
            this.editor.undoManager.transact(() => {
                let { endContainer, endOffset } =
                    this.editor.selection.getRng();
                if (endContainer.nodeType !== Node.TEXT_NODE) {
                    endContainer = endContainer.childNodes[endOffset];
                    endOffset = 0;
                }
                if (
                    endOffset > 0 &&
                    endContainer.textContent !== ZERO_WIDTH_NB_CHAR
                ) {
                    endContainer.textContent =
                        endContainer.textContent.substring(1);
                } else {
                    endContainer.previousSibling?.remove();
                }
                setCaretPosition(this.editor, caretPosition.contextElement, []);
            });
        } else if (caretPosition.path[caretPosition.path.length - 1] === '\n') {
            e.preventDefault();
            const br = caretPosition.nodePath[caretPosition.path.length - 1];
            if (br.style?.display !== 'none') {
                if (
                    isEditorElementRepresentationParagraphBreak(
                        br.previousElementSibling,
                    )
                ) {
                    const representation = br.previousElementSibling;
                    representation.remove();
                }
                br.remove();
                this.debug('Previous paragraph break removed');
                /**
                 * @type {PageDataChangedEvent}
                 */
                const pageDataChangedEvent = {
                    caretPosition: getCaretPosition(this.editor),
                };
                this.editor.fire('pageDataChanged', pageDataChangedEvent);
            } else {
                const previous = br.previousElementSibling;
                // exceptions
                if (
                    isEditorElementImage(previous) ||
                    isEditorElementImageLayout(previous)
                ) {
                    const pageNumber = previous.querySelector('.page-number');
                    const text = getClosestTextNode(pageNumber.lastChild);
                    if (text) {
                        this.editor.selection.setCursorLocation(
                            text,
                            text.textContent.length,
                        );
                        this.debug('Element focused');
                    }
                } else if (
                    isInsideAlignmentCenter(previous) ||
                    isInsideAlignmentRight(previous)
                ) {
                    const text = getClosestTextNode(previous.lastChild);
                    if (text) {
                        this.editor.selection.setCursorLocation(
                            text,
                            text.textContent.length,
                        );
                        this.debug('Alignment element focused');
                    }
                }
            }
        }
    }

    /**
     * @param e {KeyboardEvent}
     */
    arrowUpPressed(e) {
        const { line: currentLine, page: currentPage } = getCaretLine(
            this.editor,
        );
        if (!currentPage) return;
        if (currentLine === 0) {
            const previousPage = currentPage.previousSibling;
            if (previousPage) {
                e.preventDefault();
                uncachePage(this.editor, previousPage);
                const path = scanCaretPath(previousPage);
                path.pop(); // break line termination is undesired here
                setCaretPosition(this.editor, previousPage, path);
                scrollToCursorIfNeeded(this.editor);
                this.debug('Caret positioned in previous page');
            }
        }
        this.fixCaretPosition();
    }

    /**
     * @param e {KeyboardEvent}
     */
    arrowDownPressed(e) {
        const { line: currentLine, page: currentPage } = getCaretLine(
            this.editor,
        );
        if (!currentPage) return;
        const linesInPage = getLineCount(currentPage);
        // in last line of page
        if (currentLine === linesInPage - 1) {
            const nextPage = currentPage.nextSibling;
            if (nextPage) {
                e.preventDefault();
                uncachePage(this.editor, nextPage);
                setCaretPosition(this.editor, nextPage, []);
                scrollToCursorIfNeeded(this.editor);
                this.debug('Caret positioned in next page');
            }
        }
        this.fixCaretPosition();
    }

    /**
     * @param e {KeyboardEvent}
     * @param toLeft {boolean}
     */
    moveCaretHorizontally(e, toLeft) {
        const caretPosition = getCaretPosition(this.editor);
        if (!isEditorPage(caretPosition.contextElement)) return;
        let idx = caretPosition.path.length;
        if (toLeft) idx--;
        else idx++;
        const pagePath = scanCaretPath(caretPosition.page);

        let selectionTarget = pagePath[idx];
        if (selectionTarget === '\n') {
            if (toLeft && isInnerContextElement(pagePath[idx - 1])) {
                selectionTarget = pagePath[idx - 1];
            }
        }
        if (isEditorElementImage(selectionTarget)) {
            e.preventDefault();
            const target = selectionTarget.querySelector(
                toLeft ? '.page-number' : '.info-legend',
            );
            const text = getClosestTextNode(target.lastChild);
            if (text) {
                this.editor.selection.setCursorLocation(
                    text,
                    toLeft ? text.textContent.length : 0,
                );
                this.debug('Image element focused');
            }
            return;
        } else if (isEditorElementImageLayout(selectionTarget)) {
            e.preventDefault();
            const target = selectionTarget.querySelector(
                toLeft ? '.info-description' : '.page-number',
            );
            const text = getClosestTextNode(target.lastChild);
            if (text) {
                this.editor.selection.setCursorLocation(
                    text,
                    toLeft ? text.textContent.length : 0,
                );
                this.debug('Image element focused');
            }
            return;
        } else if (isEditorElementNthRoot(selectionTarget)) {
            e.preventDefault();
            const target = selectionTarget.querySelector(
                toLeft ? '.radicand' : '.index',
            );
            const text = getClosestTextNode(target);
            if (text) {
                this.editor.selection.setCursorLocation(
                    text,
                    toLeft ? text.textContent.length : 0,
                );
                this.debug('Nth root element focused');
            }
            return;
        } else if (
            isEditorElementLineSegment(selectionTarget) ||
            isEditorElementAngle(selectionTarget)
        ) {
            e.preventDefault();
            const target = selectionTarget.querySelector('.value');
            const text = getClosestTextNode(target);
            if (text) {
                this.editor.selection.setCursorLocation(
                    text,
                    toLeft ? text.textContent.length : 0,
                );
                this.debug('Line segment/angle element focused');
            }
            return;
        }
        e.preventDefault();
        setCaretPosition(
            this.editor,
            caretPosition.contextElement,
            pagePath.slice(0, idx),
        );
    }

    /**
     * @param e {KeyboardEvent}
     */
    arrowLeftPressed(e) {
        if (e.ctrlKey || e.shiftKey || e.altKey) return;
        const caretPosition = getCaretPosition(this.editor);
        if (!caretPosition) return;
        const closestEditorElement = getClosestEditorElement(
            caretPosition.contextElement,
        );
        if (isInnerContextElement(closestEditorElement)) {
            // let default tiny behavior
            return;
        }
        this.moveCaretHorizontally(e, true);
    }

    /**
     * @param e {KeyboardEvent}
     */
    arrowRightPressed(e) {
        if (e.ctrlKey || e.shiftKey || e.altKey) return;
        const caretPosition = getCaretPosition(this.editor);
        if (!caretPosition) return;
        const closestEditorElement = getClosestEditorElement(
            caretPosition.contextElement,
        );
        if (isInnerContextElement(closestEditorElement)) {
            // let default tiny behavior
            return;
        }
        this.moveCaretHorizontally(e, false);
    }

    /**
     * @param e {KeyboardEvent}
     */
    deletePressed(e) {
        const caretPosition = getCaretPosition(this.editor);
        if (!caretPosition) return;
        const contextPath = scanCaretPath(caretPosition.contextElement);

        if (removeEmptyPage(this.editor, caretPosition.contextElement)) {
            e.preventDefault();
            return;
        }

        const pagePath = scanCaretPath(caretPosition.contextElement);
        const lastPos = pagePath.length - 1 === caretPosition.path.length;

        // page always have a break line at end
        if (
            isEditorPage(caretPosition.contextElement) &&
            contextPath.length - 1 === caretPosition.path.length
        ) {
            const nextPage = caretPosition.contextElement.nextSibling;
            if (nextPage && lastPos) {
                e.preventDefault();
                setCaretPosition(
                    this.editor,
                    caretPosition.page,
                    caretPosition.path,
                );
                return;
            } else {
                // bug when last char of page are removed delete all page
                e.preventDefault();
                this.debug('Delete cancelled to remove page element');
                return;
            }
        }
        if (pagePath[caretPosition.path.length] === '\n') {
            e.preventDefault();
            let br;
            if (caretPosition.path.length) {
                br =
                    caretPosition.nodePath[caretPosition.nodePath.length - 1]
                        ?.nextElementSibling;
            } else {
                br = caretPosition.contextElement.firstElementChild;
            }
            if (isEditorElementRepresentationParagraphBreak(br)) {
                const representation = br;
                br = br.nextElementSibling;
                representation.remove();
            }
            if (br) {
                br.remove();
                this.debug('Next paragraph break removed');
                /**
                 * @type {PageDataChangedEvent}
                 */
                const pageDataChangedEvent = {
                    caretPosition: getCaretPosition(this.editor),
                };
                this.editor.fire('pageDataChanged', pageDataChangedEvent);
            }
            this.firePageDataChangedOnKeyUp = true;
        }
    }

    /**
     * @param e {KeyboardEvent}
     */
    pageUp(e) {
        e.preventDefault();
        const pages = getPages(this.editor);
        const currentPage = getCurrentPage(this.editor);
        const goToIdx = pages.indexOf(currentPage) - 1;
        if (goToIdx < 0) {
            goToPage(this.editor, 0);
            setCaretPosition(this.editor, pages[0], []);
            return;
        }
        goToPage(this.editor, goToIdx);
        const toPage = pages[goToIdx];
        uncachePage(this.editor, toPage);
        setCaretPosition(this.editor, toPage, scanCaretPath(toPage));
    }

    /**
     * @param e {KeyboardEvent}
     */
    pageDown(e) {
        e.preventDefault();
        const pages = getPages(this.editor);
        const currentPage = getCurrentPage(this.editor);
        const goToIdx = pages.indexOf(currentPage) + 1;
        if (goToIdx >= pages.length) {
            goToPage(this.editor, pages.length - 1);
            setCaretPosition(
                this.editor,
                pages[pages.length - 1],
                scanCaretPath(pages[pages.length - 1]),
            );
            return;
        }
        goToPage(this.editor, goToIdx);
        const toPage = pages[goToIdx];
        uncachePage(this.editor, toPage);
        setCaretPosition(this.editor, toPage, []);
    }

    /**
     * @param e {KeyboardEvent}
     */
    keyDown(e) {
        if (isMultipleSelection(this.editor)) return;
        const currentPage = getCurrentPage(this.editor);
        if (currentPage) {
            this.keyDownCurrentPage = currentPage;
            this.keyDownLineCount = getLineCount(currentPage);
        }
        if (e.key !== 'Enter' && (e.shiftKey || e.altKey)) return;

        switch (e.key) {
            case 'Enter':
                this.enterPressed(e);
                break;
            case 'Backspace':
                this.backspacePressed(e);
                break;
            case 'ArrowUp':
                this.arrowUpPressed(e);
                break;
            case 'ArrowDown':
                this.arrowDownPressed(e);
                break;
            case 'ArrowLeft':
                this.arrowLeftPressed(e);
                break;
            case 'ArrowRight':
                this.arrowRightPressed(e);
                break;
            case 'Delete':
                this.deletePressed(e);
                break;
            case 'PageUp':
                this.pageUp(e);
                break;
            case 'PageDown':
                this.pageDown(e);
                break;
        }
    }

    /**
     * @param toRight {boolean | undefined}
     */
    fixCaretPosition(toRight = true) {
        if (this.editor.custom.isShowingNonPrintableChars) {
            let representation = getClosestElementRepresentation(
                this.editor.selection.getRng().endContainer,
            );
            if (representation) {
                /**
                 * @param element {Node}
                 */
                function isZeroWidthNbChar(element) {
                    return (
                        element &&
                        element.nodeType === Node.TEXT_NODE &&
                        element.textContent === ZERO_WIDTH_NB_CHAR
                    );
                }

                const isLineBreak =
                    isEditorElementRepresentationParagraphBreak(representation);
                const offset = isLineBreak ? 0 : 1;
                let insertLeft = !isLineBreak;
                if (toRight) insertLeft = !insertLeft;

                let textNode;
                if (insertLeft) {
                    if (isZeroWidthNbChar(representation.previousSibling)) {
                        textNode = representation.previousSibling;
                    } else {
                        textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                        representation.before(textNode);
                    }
                    this.editor.selection.setCursorLocation(textNode, offset);
                } else {
                    if (isZeroWidthNbChar(representation.nextSibling)) {
                        textNode = representation.nextSibling;
                    } else {
                        textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                        representation.after(textNode);
                    }
                    this.editor.selection.setCursorLocation(textNode, offset);
                }
            }
        }
        // this happens when you have a <editor-element><br><editor-element> (image/summary) and removes the <br> between
        if (isTinyCaret(this.editor.selection.getNode())) {
            const caretPosition = getCaretPosition(this.editor);
            setCaretPosition(
                this.editor,
                caretPosition.contextElement,
                caretPosition.path,
            );
        }
    }

    keyUp() {
        if (this.firePageDataChangedOnKeyUp) {
            try {
                const caretPosition = getCaretPosition(this.editor);
                if (!caretPosition) return;
                /**
                 * @type {PageDataChangedEvent}
                 */
                const pageDataChangedEvent = {
                    caretPosition,
                };
                this.editor.fire('pageDataChanged', pageDataChangedEvent);
            } finally {
                this.firePageDataChangedOnKeyUp = false;
            }
        }
    }

    // REVIEW: @cassío, como o padrão não permite adicionar os eventos do editor dentro dos arquivos dos elementos, deixei aqui.
    // Podemos pensar em como adaptar melhor.
    /**
     * @param e {InputEvent}
     */
    beforeInput(e) {
        const selectedNode = this.editor.selection.getNode();
        const isSelectedNodeEmpty = !selectedNode.textContent.trim();

        if (e?.inputType === 'insertLineBreak') {
            if (
                isInsideEditorElementNthRoot(selectedNode) ||
                isInsideEditorElementLineSegment(selectedNode) ||
                isInsideEditorElementAngle(selectedNode)
            ) {
                e.preventDefault();
                this.debug('Enter prevented in unsupported element');
            }
        } else if (e?.inputType === 'deleteContentBackward') {
            if (isInsideEditorElementHeading(selectedNode)) {
                const element = getClosestEditorElementHeading(selectedNode);
                switch (Number(element.getAttribute('data-heading-size'))) {
                    case 1:
                        const isBothValuesEmpty =
                            !element
                                .querySelector('.value')
                                .textContent.trim() &&
                            !element
                                .querySelector('.second-value')
                                .textContent.trim();
                        if (isBothValuesEmpty) {
                            e.preventDefault();
                            removeCurrentEditorElementHeading(this.editor);
                        }
                        break;
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        if (isSelectedNodeEmpty) {
                            e.preventDefault();
                            removeCurrentEditorElementHeading(this.editor);
                        }
                        break;
                }
            } else {
                const caretPosition = getCaretPosition(this.editor);
                if (!caretPosition) return;
                let previousElement =
                    caretPosition.nodePath[caretPosition.path.length - 1];
                if (isEditorElementHeading(previousElement)) {
                    e.preventDefault();
                    // REVIEW: @cassio, por algum motivo, se o título for o primeiro conteúdo da página, a mecânica de focus não funciona e o elemento é apagado.
                    // Pode ser que a refatoração da mecânica para transformar em braille resolva.
                    focusOnCurrentEditorElementHeading(
                        this.editor,
                        previousElement,
                    );
                }
            }
        }
    }

    install() {
        const userDescriptor =
            this.userDocumentRoles.includes(RoleEnum.DESCRIPTION) &&
            // users may have another roles in same document
            this.userDocumentRoles.length === 1;

        if (this.readOnly) {
            this.editor.on('keyDown', (e) => {
                // noinspection JSUnresolvedReference
                const preventDefault = e.key === 'Enter';
                if (preventDefault) {
                    // noinspection JSUnresolvedReference
                    e.preventDefault();
                }
            });
            this.editor.on('beforeExecCommand', (e) => {
                // noinspection JSUnresolvedReference
                e.preventDefault();
            });
        } else if (userDescriptor) {
            this.editor.on('keyDown', (e) => {
                // noinspection JSUnresolvedReference
                const preventDefault = e.key === 'Enter';
                if (preventDefault) {
                    // noinspection JSUnresolvedReference
                    e.preventDefault();
                    return;
                }
                this.keyDown(e);
            });
        } else {
            this.editor.on('keyDown', (e) => {
                this.keyDown(e);
            });
        }
        this.editor.on('keyUp', () => this.keyUp());
        this.editor.on('beforeInput', (e) => this.beforeInput(e));
    }
}
