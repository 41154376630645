/**
 * @enum {number}
 */
export const BrailleFacilConversionFlag = {
    INSIDE_PAGE: 0,
    INSIDE_HIGHLIGHT: 1,
    INSIDE_UNDERLINE: 2,
    INSIDE_RECOIL: 3,
    CONTEXT_COMPUTER_RELATED: 4,
    CONTEXT_MATH: 5,
    CONTEXT_IMAGE: 6,
    CONTEXT_CATALOG: 7,
    RAW_BRAILLE_OUTPUT: 8,
};
