import { createNewPage, getCurrentPage } from './core/PageManipulation';
import {
    getBrailleDocument,
    getRootNodeInPage,
    isEditorElement,
    isEditorElementParagraphBreak,
} from './core/EditorUtil';
import { getLineCount } from './core/CaretPath';
import { getPageCache } from './core/Cache';

export class ExcessLinesControlModule {
    /**
     * @type {null | number}
     */
    beforeChangeRowCount = null;

    pageDataChangeIgnoreLinePullThisTime = false;

    /**
     * @param editor {EditorCustom)
     */
    constructor(editor) {
        this.editor = editor;
        this.brailleDocument = getBrailleDocument(this.editor);
    }

    /**
     * @returns {BrailleDocument | null}
     */
    getBrailleDocument() {
        return getBrailleDocument(this.editor);
    }

    /**
     * @return {boolean}
     */
    active() {
        const brailleDocument = this.getBrailleDocument();
        return !!brailleDocument?.convertedToBraille;
    }

    install() {
        const self = this;

        this.editor.on('brailleDataChanged', ({ page }) => {
            if (!this.active()) {
                return;
            }
            self.pageDataChangeIgnoreLinePullThisTime =
                this.pushExcessLinesToNextPage(page).length !== 0;
        });

        this.editor.on('keyDown', () => {
            if (!this.active()) {
                return;
            }
            const brailleDocument = this.getBrailleDocument();
            self.beforeChangeRowCount = getLineCount(
                getCurrentPage(self.editor),
            );
            if (
                self.beforeChangeRowCount > brailleDocument.brailleCellRowCount
            ) {
                self.beforeChangeRowCount = brailleDocument.brailleCellRowCount;
            }
        });

        this.editor.on('pageDataChanged', ({ caretPosition }) => {
            if (self.pageDataChangeIgnoreLinePullThisTime) {
                self.pageDataChangeIgnoreLinePullThisTime = false;
                return;
            }
            const { page } = caretPosition;
            const lineCount = getLineCount(page);
            if (lineCount < self.beforeChangeRowCount) {
                // some lines are remove, needs to pull from another page
                self.pullLinesFromNextPage(page, lineCount);
            }
        });
    }

    /**
     * @param page {HTMLElement}
     * @param lineCount {number}
     */
    pullLinesFromNextPage(page, lineCount) {
        let nextPage = page.nextElementSibling;
        if (!nextPage) {
            return;
        }
        const nextPageContainer =
            getPageCache(this.editor, nextPage, false) ?? nextPage;
        const maxLines = getBrailleDocument(this.editor).brailleCellRowCount;
        const linesToPull = maxLines - lineCount;
        const nextPageElements =
            this.getBrailleLinesElements(nextPageContainer);
        for (let i = 0; i < linesToPull; i++) {
            const paragraphElements = nextPageElements[i];
            if (!paragraphElements) break;
            for (const element of paragraphElements) {
                const rootNodeInPage = getRootNodeInPage(element);
                if (!rootNodeInPage) continue;
                const nodeLineCount = getLineCount(rootNodeInPage);
                if (
                    isEditorElement(rootNodeInPage) &&
                    nodeLineCount > linesToPull - i
                ) {
                    break;
                }
                page.append(rootNodeInPage);
            }
        }
        if (linesToPull) {
            page.setAttribute('data-needs-update', 'true');
            nextPage.setAttribute('data-needs-update', 'true');
        }
    }

    /**
     * @param page {HTMLElement}
     * @return {HTMLElement[][]}
     */
    pushExcessLinesToNextPage(page) {
        const excessLinesOfPage = this.removeExcessLines(
            page,
            this.getBrailleDocument().brailleCellRowCount,
        );

        if (!excessLinesOfPage.length) return excessLinesOfPage;

        let nextPage = page.nextElementSibling;
        if (!nextPage) {
            nextPage = createNewPage(this.editor);
            page.after(nextPage);
        }
        const nextPageContainer =
            getPageCache(this.editor, nextPage, false) ?? nextPage;
        excessLinesOfPage
            .slice()
            .reverse()
            .forEach((row) => {
                row.slice()
                    .reverse()
                    .forEach((line) => nextPageContainer.prepend(line));
            });

        nextPage.setAttribute('data-needs-update', 'true');
        return excessLinesOfPage;
    }

    /**
     * @param page {HTMLElement}
     * @param maxRows {number}
     * @return {HTMLElement[][]}
     */
    removeExcessLines(page, maxRows) {
        const brailleLinesElements = this.getBrailleLinesElements(page);
        /**
         * @type {HTMLElement[][]}
         */
        const excessLines = [];
        for (let i = maxRows; i < brailleLinesElements.length; i++) {
            const lineElements = brailleLinesElements[i];
            /**
             * @type {HTMLElement[]}
             */
            let currentLine = [];
            for (const element of lineElements) {
                const rootNodeInPage = getRootNodeInPage(element);
                if (!rootNodeInPage) continue;
                currentLine.push(rootNodeInPage);
                rootNodeInPage.remove();
            }
            excessLines.push(currentLine);
        }
        return excessLines;
    }

    /**
     * @param page {HTMLElement | DocumentFragment}
     * @return {HTMLElement[][]}
     */
    getBrailleLinesElements(page) {
        /**
         * @type {HTMLElement[][]}
         */
        let lines = [];
        getLineCount(page, (lineCount, node) => {
            const i = lineCount;
            let array = lines[i];
            if (!array) {
                array = [];
                lines[i] = array;
            }
            if (!array.includes(node)) {
                array.push(node);
            }
        });

        for (let i = 0; i < lines.length; i++) {
            let array = lines[i];
            if (!array) {
                array = [];
                lines[i] = array;
            }
            const firstElement = array[0];
            if (
                i > 0 &&
                firstElement &&
                (firstElement.tagName === 'BR' ||
                    isEditorElementParagraphBreak(firstElement))
            ) {
                lines[i - 1].push(firstElement);
                array.splice(0, 1);
            }
        }
        lines = lines.filter((array) => {
            return array.length;
        });
        return lines;
    }
}
