import {
    generateId,
    getClosestEditorElement,
    getPagesInvolved,
    getSelectedNodes,
    getSelectedParagraphs,
    isEditorElement,
    isEditorElementRepresentationParagraphBreak,
    isInside,
} from '../EditorUtil';
import {
    isInsideEditorElementImage,
    isInsideEditorElementImageLayout,
} from './EditorElementImage';
import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import { getCaretPosition, setCaretPosition } from '../CaretPath';
import {
    createInvisibleParagraphBreak,
    fixInvisibleParagraphBreak,
} from '../EditorElements';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { EDITOR_ELEMENTS_MAP } from './Instances';
import { isInsideEditorElementHeading } from './EditorElementHeading';

export const EDITOR_ELEMENT_ALIGNMENT_CENTER =
    'EDITOR_ELEMENT_ALIGNMENT_CENTER';
export const EDITOR_ELEMENT_ALIGNMENT_RIGHT = 'EDITOR_ELEMENT_ALIGNMENT_RIGHT';
export const EDITOR_ELEMENT_ALIGNMENT_LEFT = 'EDITOR_ELEMENT_ALIGNMENT_LEFT';

/**
 * @enum {string}
 */
export const AlignmentType = {
    CENTER: 'alignment-center',
    RIGHT: 'alignment-right',
    LEFT: 'alignment-left',
};

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementAlignmentCenter(node) {
    return (
        node?.getAttribute && node.getAttribute('type') === 'alignment-center'
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideAlignmentCenter(node) {
    return isInside(
        node,
        (node) => isEditorElement(node) && isEditorElementAlignmentCenter(node),
    );
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementAlignment(node) {
    return (
        node?.getAttribute &&
        node?.getAttribute('type')?.startsWith('alignment-')
    );
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementAlignmentRight(node) {
    return (
        node?.getAttribute && node.getAttribute('type') === 'alignment-right'
    );
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideAlignmentRight(node) {
    return isInside(
        node,
        (node) => isEditorElement(node) && isEditorElementAlignmentRight(node),
    );
}

/**
 * @param node {HTMLElement | Node}
 * @return {HTMLElement | null}
 */
export function getClosestElementAlignment(node) {
    let walk = node;
    while (walk) {
        if (isEditorElementAlignment(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param editor {EditorCustom}
 * @param type {AlignmentType}
 * @returns {HTMLElement}
 */
function createEditorElementAlignment(editor, type) {
    let alignmentContainer = editor.dom.create('editor-element', {
        type,
    });
    const idPrefix = `editor-element-alignment-${type}`;
    const elementId = generateId(editor, idPrefix);
    alignmentContainer.setAttribute('id', elementId);
    const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
    alignmentContainer.appendChild(textNode);
    return alignmentContainer;
}

/**
 * @param editor {EditorCustom}
 * @param type {AlignmentType}
 */
function changeAlignment(editor, type) {
    editor.undoManager.transact(() => {
        const node = editor.selection.getNode();
        const editorElement = getClosestEditorElement(node);
        if (
            isInsideEditorElementImage(editorElement) ||
            isInsideEditorElementImageLayout(editorElement) ||
            isInsideEditorElementHeading(editorElement)
        ) {
            editor.notificationManager.open({
                // I18N
                text: 'Não é possível alterar o alinhamento na seleção atual',
                type: 'warning',
                timeout: 5000,
            });
            return false;
        }
        if (getPagesInvolved(getSelectedNodes(editor)).length > 1) {
            editor.notificationManager.open({
                // I18N
                text: 'Não é possível aplicar alinhamento em mais de uma página',
                type: 'warning',
                timeout: 5000,
            });
            return false;
        }

        function appendNewAlignmentContainerInSelection() {
            let editorElement = createEditorElementAlignment(editor, type);
            const id = editorElement.getAttribute('id');
            editor.selection.setContent(editorElement.outerHTML);
            editorElement = editor.dom.get(id);
            /**
             * @type {Node}
             */
            const textNode = editorElement.firstChild;
            editor.selection.setCursorLocation(textNode, 0);
            editorElement.after(createInvisibleParagraphBreak());
            return editorElement;
        }

        const selectedParagraphs = getSelectedParagraphs(editor);
        if (!selectedParagraphs.length) {
            appendNewAlignmentContainerInSelection();
            return;
        }

        const caretPosition = getCaretPosition(editor);
        for (let paragraph of selectedParagraphs) {
            if (type !== AlignmentType.LEFT) {
                if (!paragraph.length) {
                    continue;
                }
                removeAlignment(editor, paragraph);
                let alignmentContainer;
                alignmentContainer = editor.dom.create('editor-element', {
                    type,
                });
                const firstElement = paragraph[0];
                firstElement.parentElement.insertBefore(
                    alignmentContainer,
                    firstElement,
                );
                for (let element of paragraph) {
                    alignmentContainer.appendChild(element);
                }
                if (!alignmentContainer.innerText.trim().length) {
                    alignmentContainer.innerHTML = ZERO_WIDTH_NB_CHAR;
                }
                if (alignmentContainer.nextElementSibling?.tagName === 'BR') {
                    const br = alignmentContainer.nextElementSibling;
                    br.style.display = 'none';
                }
            } else {
                removeAlignment(editor, paragraph);
            }
        }

        if (caretPosition)
            setCaretPosition(
                editor,
                caretPosition?.contextElement,
                caretPosition.path,
            );
        editor.selection.collapse(true);
        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = {
            caretPosition: getCaretPosition(editor),
        };
        editor.fire('pageDataChanged', pageDataChangedEvent);
    });

    /**
     * @param editor {EditorCustom}
     * @param paragraph {Node[] | undefined}
     */
    function removeAlignment(editor, paragraph = null) {
        const alignment = getClosestElementAlignment(
            paragraph[0] ?? editor.selection.getNode(),
        );
        if (!alignment) return;
        /**
         * @type {HTMLBRElement | null}
         */
        const br = alignment?.nextElementSibling;
        if (br?.tagName === 'BR' && br?.style?.display === 'none') {
            br.style?.removeProperty('display');
        }
        const caretPosition = getCaretPosition(editor);
        const childNodes = document.createDocumentFragment();
        for (let child of [...alignment.childNodes]) {
            if (isEditorElementRepresentationParagraphBreak(child)) continue;
            childNodes.appendChild(child);
        }
        alignment.replaceWith(childNodes);
        if (caretPosition)
            setCaretPosition(
                editor,
                caretPosition.contextElement,
                caretPosition.path,
            );
    }
}

/**
 * @param element {HTMLElement | Node}
 * @param type {AlignmentType}
 */
function checkAndRepairElements(element, type) {
    let elements = [
        ...element.querySelectorAll(`editor-element[type="${type}"]`),
    ];
    for (const element of elements) {
        if (
            !element.lastChild ||
            element.lastChild.nodeType !== Node.TEXT_NODE ||
            element.lastChild.textContent !== ZERO_WIDTH_NB_CHAR
        ) {
            const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
            element.appendChild(textNode);
        }
        fixInvisibleParagraphBreak(element);
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementAlignment {
    constructor() {}

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return [];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    hasInvisibleParagraphBreak() {
        return true;
    }

    /**
     * @return {string[]}
     */
    getContextMenu() {
        return [];
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementAlignmentCenter extends EditorElementAlignment {
    constructor() {
        super();
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return AlignmentType.CENTER;
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isEditorElementAlignmentCenter(node);
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null) {
        return createEditorElementAlignment(editor, AlignmentType.CENTER);
    }

    /**
     * @param editor {EditorCustom}
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        changeAlignment(editor, AlignmentType.CENTER);
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBrailleFacil(element, flags, editorElements, brailleDocument) {
        let extractedTxt = '';
        for (const childNode of element.childNodes) {
            extractedTxt += extractRecursively(
                childNode,
                flags,
                editorElements,
                brailleDocument,
            );
        }
        let txt = '';
        for (let paragraph of extractedTxt.split('\n')) {
            txt += '\uFEFF'.repeat(10) + paragraph + '\n';
        }
        if (txt.length && !extractedTxt.endsWith('\n')) {
            txt = txt.substring(0, txt.length - 1);
        }
        return txt;
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElements(element) {
        checkAndRepairElements(element, AlignmentType.CENTER);
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementAlignmentRight extends EditorElementAlignment {
    constructor() {
        super();
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return AlignmentType.RIGHT;
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isEditorElementAlignmentRight(node);
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null) {
        return createEditorElementAlignment(editor, AlignmentType.RIGHT);
    }

    /**
     * @param editor {EditorCustom}
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        changeAlignment(editor, AlignmentType.RIGHT);
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBrailleFacil(element, flags, editorElements, brailleDocument) {
        let txt = '';
        for (const childNode of element.childNodes) {
            txt += extractRecursively(
                childNode,
                flags,
                editorElements,
                brailleDocument,
            );
        }
        return txt;
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElements(element) {
        checkAndRepairElements(element, AlignmentType.RIGHT);
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementAlignmentLeft extends EditorElementAlignment {
    constructor() {
        super();
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return AlignmentType.LEFT;
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line no-unused-vars
    isNodeInsideElement(node) {
        return false;
    }

    /**
     * @returns {Error}
     * @private
     */
    _unsupported() {
        return new Error('Unsupported operation');
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line no-unused-vars
    createEditorElement(editor = null) {
        throw this._unsupported();
    }

    /**
     * @param editor {EditorCustom}
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        changeAlignment(editor, AlignmentType.LEFT);
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line no-unused-vars
    convertToBrailleFacil(element, flags, editorElements, brailleDocument) {
        throw this._unsupported();
    }

    /**
     * @param element {HTMLElement}
     */
    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line no-unused-vars
    checkAndRepairElements(element) {}
}

EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_ALIGNMENT_CENTER] =
    new EditorElementAlignmentCenter();
EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_ALIGNMENT_RIGHT] =
    new EditorElementAlignmentRight();
EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_ALIGNMENT_LEFT] =
    new EditorElementAlignmentLeft();
