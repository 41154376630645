import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getClosestEditorElementRecoil,
    getSelectedParagraphs,
    isInsideEditorElementRecoil,
} from '../EditorUtil';
import {
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
} from '../EditorElements';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { EDITOR_ELEMENTS_MAP } from './Instances';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';

export const EDITOR_ELEMENT_RECOIL = 'EDITOR_ELEMENT_RECOIL';

/**
 * @param editor {EditorCustom | null}
 * @param selection {string | null | undefined}
 * @returns {HTMLElement}
 */
export function createEditorElementRecoil(editor, selection = null) {
    const editorElement = document.createElement('editor-element');
    const elementId = generateId(editor, 'editor-element-recoil');
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'recoil');
    editorElement.setAttribute('id', elementId);

    const recoilText = document.createElement('div');
    recoilText.className = 'text';
    recoilText.setAttribute('contentEditable', 'true');
    recoilText.innerHTML = selection?.trim()
        ? selection.trim()
        : ZERO_WIDTH_NB_CHAR;
    editorElement.appendChild(recoilText);

    return editorElement;
}

/**
 * @implements {EditorElement}
 */
export class EditorElementRecoil {
    constructor() {}

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'recoil';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementRecoil(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    hasInvisibleParagraphBreak() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null) {
        return createEditorElementRecoil(editor);
    }

    /**
     * @param editor {EditorCustom}
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const editorElementRecoil = getClosestEditorElementRecoil(
            editor.selection.getNode(),
        );

        if (editorElementRecoil) {
            // remove recoil
            editor.undoManager.transact(() => {
                const fragment = document.createDocumentFragment();
                const container = editorElementRecoil.querySelector('.text');
                for (const child of [...container.childNodes]) {
                    fragment.appendChild(child);
                }
                editorElementRecoil.replaceWith(fragment);
            });
            return true;
        }

        const editorElement = createEditorElementRecoil(editor);
        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return false;
        }

        editor.undoManager.transact(() => {
            /**
             * @type {Node}
             */
            const documentFragment = document.createDocumentFragment();
            documentFragment.appendChild(editorElement);
            documentFragment.appendChild(createInvisibleParagraphBreak());
            const selectedParagraphs = getSelectedParagraphs(editor);
            if (selectedParagraphs.length !== 1 || selectedParagraphs.length) {
                // has content
                const contentFragment = document.createDocumentFragment();
                for (const [i, paragraph] of selectedParagraphs.entries()) {
                    const br =
                        paragraph[paragraph.length - 1]?.nextElementSibling;
                    if (br?.tagName === 'BR') {
                        br.remove();
                    }
                    for (const node of paragraph) {
                        contentFragment.appendChild(node);
                    }
                    if (i < selectedParagraphs.length - 1) {
                        contentFragment.appendChild(
                            document.createElement('br'),
                        );
                    }
                }
                editorElement
                    .querySelector('.text')
                    ?.appendChild(contentFragment);
            }
            editor.selection.setNode(documentFragment);
            return false;
        });
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBrailleFacil(element, flags, editorElements, brailleDocument) {
        const textContainer = element.querySelector('.text');
        if (!textContainer) return '';
        if (flags.includes(BrailleFacilConversionFlag.INSIDE_RECOIL)) {
            let txt = '';
            for (const childNode of textContainer.childNodes) {
                txt += extractRecursively(
                    childNode,
                    flags,
                    editorElements,
                    brailleDocument,
                );
            }
            return txt;
        }

        let txt;
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        if (rawOutput) {
            txt = MARK_CHAR.RECOIL_BLOCK;
        } else {
            txt = '<R+>\n';
        }
        for (const childNode of textContainer.childNodes) {
            txt += extractRecursively(
                childNode,
                [...flags, BrailleFacilConversionFlag.INSIDE_RECOIL],
                editorElements,
                brailleDocument,
            );
        }
        if (txt.endsWith('\n')) {
            txt = txt.substring(0, txt.length - 2);
        }
        if (rawOutput) {
            txt += MARK_CHAR.RECOIL_BLOCK;
        } else {
            txt += '\n<R->';
        }
        return txt;
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElements(element) {
        /**
         * @type {HTMLElement[]}
         */
        const elements = [
            ...element.querySelectorAll('editor-element[type="recoil"]'),
        ];
        for (let element of elements) {
            if (!element.querySelector('.text')) {
                const textDiv = document.createElement('div');
                textDiv.className = 'text';
                let text = element.querySelector('.text');
                if (!text) {
                    text = document.createElement('div');
                    text.className = 'text';
                    element.innerHTML = '';
                    element.appendChild(textDiv);
                    element.appendChild(text);
                }
                if (!text.innerText.trim()) {
                    text.innerHTML = ZERO_WIDTH_NB_CHAR;
                }
            }
            fixInvisibleParagraphBreak(element);
        }
    }

    /**
     * @return {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }
}

EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_RECOIL] = new EditorElementRecoil();
