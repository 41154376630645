/**
 * @type {Object<string, EditorElement>}
 */
export let EDITOR_ELEMENTS_MAP = {};

/**
 * @type {null | string[]}
 */
let innerContextContainerClasses = null;

/**
 * @returns {string[]}
 */
export function getInnerContextContainerClasses() {
    if (innerContextContainerClasses) {
        return innerContextContainerClasses;
    }

    /**
     * @type {string[]}
     */
    const uniqueClasses = [];
    /**
     * @type {Set<string>}
     */
    const classNamesSet = new Set();

    for (const editorElement of Object.values(EDITOR_ELEMENTS_MAP)) {
        for (const className of editorElement.getInnerContextContainerCssClass()) {
            if (!classNamesSet.has(className)) {
                classNamesSet.add(className);
                uniqueClasses.push(className);
            }
        }
    }
    innerContextContainerClasses = uniqueClasses;
    return innerContextContainerClasses;
}
