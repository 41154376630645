import {
    addParagraphBreakAboveSel,
    addParagraphBreakBellowSel,
} from '../core/PageManipulation';
import { removeEditorElementSel } from '../core/EditorElements';
import { getBrailleDocument } from '../core/EditorUtil';

/**
 * @param editor {EditorCustom}
 * @param params {MenuModuleParams}
 */
// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line no-unused-vars
export function createContextMenu(editor, params) {
    editor.ui.registry.addMenuItem('customContextMenuAddParagraphBreakAbove', {
        icon: 'action-prev',
        // I18N
        text: 'Inserir parágrafo acima',
        onAction: function () {
            addParagraphBreakAboveSel(editor);
        },
        onSetup: (api) => {
            api.setEnabled(!getBrailleDocument(editor).readOnly);
        },
    });

    editor.ui.registry.addMenuItem('customContextMenuAddParagraphBreakBellow', {
        icon: 'action-next',
        // I18N
        text: 'Inserir parágrafo abaixo',
        onAction: function () {
            addParagraphBreakBellowSel(editor);
        },
        onSetup: (api) => {
            api.setEnabled(!getBrailleDocument(editor).readOnly);
        },
    });
    editor.ui.registry.addMenuItem('customContextMenuRemove', {
        icon: 'remove',
        // I18N
        text: 'Remover',
        onAction: function () {
            removeEditorElementSel(editor);
        },
        onSetup: (api) => {
            api.setEnabled(!getBrailleDocument(editor).readOnly);
        },
    });

    // do not change the menu name, will stop to work
    editor.ui.registry.addContextMenu('image', {
        update: function (element) {
            const editorElements = editor.custom.coreModule.editorElements;
            return editorElements.getContextMenu(element);
        },
    });
}
