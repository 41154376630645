import { getParagraphsInText, scanCaretPath } from '../core/CaretPath';
import { RevisionRecord } from './RevisionRecord';

import { getInnerContextContainerClasses } from '../core/editor-element/Instances';

/**
 * @typedef {object} WorkerResponse
 * @property {number} startPos
 * @property {number} length
 * @property {string} word
 * @property {RevisionErrorEnum | null | undefined} revisionError
 */

/**
 * @param worker
 * @param page {HTMLElement | Node}
 * @param pageNumber {number}
 * @param revisionGravity {RevisionGravityEnum}
 * @param revisionError {RevisionErrorEnum}
 * @return {Promise<RevisionRecord[]>}
 */
export async function executeInspection(
    worker,
    page,
    pageNumber,
    revisionGravity,
    revisionError,
) {
    const records = [];
    const paragraphs = getParagraphsInText(page);

    for (const [i, paragraph] of paragraphs.entries()) {
        if (typeof paragraph === 'object') {
            let fragmentIdx = 0;
            for (const containerClass of getInnerContextContainerClasses()) {
                const container = paragraph.querySelector(containerClass);
                if (!container) continue;
                const fragmentText = scanCaretPath(
                    container,
                    null,
                    null,
                    null,
                    true,
                ).join('');
                const errors = await executeWorker(worker, fragmentText);
                for (const error of errors) {
                    records.push(
                        new RevisionRecord(
                            revisionGravity,
                            error.revisionError ?? revisionError,
                            pageNumber,
                            i,
                            fragmentIdx,
                            error.startPos,
                            error.length,
                            error.word,
                        ),
                    );
                }
                fragmentIdx++;
            }
        } else {
            const errors = await executeWorker(worker, paragraph);
            for (const error of errors) {
                records.push(
                    new RevisionRecord(
                        revisionGravity,
                        error.revisionError ?? revisionError,
                        pageNumber,
                        i,
                        0,
                        error.startPos,
                        error.length,
                        error.word,
                    ),
                );
            }
        }
    }
    return records;
}

/**
 * @param worker
 * @param paragraph {string}
 * @return {Promise<WorkerResponse[]>}
 */
async function executeWorker(worker, paragraph) {
    return await new Promise((resolve, reject) => {
        worker.postMessage({ paragraph });
        worker.onmessage = (result) => {
            resolve(result.data);
        };
        worker.onerror = (error) => {
            reject(error.error);
        };
    });
}
