import {
    getRootNodeInPage,
    getBrailleDocument,
    getClosestEditorElement,
    isEditorElementParagraphBreak,
    isEditorPage,
    pageHasText,
    removeNextSiblingChain,
    replaceNodeEdgeSpacesWithNbsp,
    replaceSelectedNodes,
} from './EditorUtil';
import {
    getCaretPosition,
    getLineCount,
    scanCaretPath,
    setCaretPosition,
} from './CaretPath';
import { updateScroll } from '../ScrollModule';
import { uncachePage } from './Cache';
import { DOCUMENT_VERSION } from './CoreModule';
import { isInnerContextElement } from './EditorElements';
import { isEditorBrailleView } from './BrailleView';

/**
 * @param editor {EditorCustom}
 * @returns {HTMLElement[]}
 */
export function getPages(editor) {
    return editor?.dom?.select('editor-page') ?? [];
}

/**
 * @param editor {EditorCustom}
 * @return {HTMLElement | null}
 */
export function getCurrentPage(editor) {
    let walk = editor?.selection?.getNode();
    while (walk) {
        if (isEditorPage(walk)) return walk;
        walk = walk.parentNode;
    }
    return null;
}

/**
 * @param editor {EditorCustom}
 * @param updateNewPage {boolean | undefined}
 * @return {*}
 */
export function insertBlankPage(editor, updateNewPage = true) {
    let newPage;
    editor.undoManager.transact(() => {
        const root = editor.dom.getRoot();
        newPage = editor.dom.create('editor-page');
        newPage.setAttribute('data-document-version', DOCUMENT_VERSION);
        const pages = getPages(editor);
        const currentPage = getCurrentPage(editor);
        const currentPageIndex =
            currentPage == null ? pages.length - 1 : pages.indexOf(currentPage);
        if (currentPageIndex < pages.length - 1) {
            root.insertBefore(newPage, pages[currentPageIndex + 1]);
        } else {
            root.append(newPage);
        }
    });
    if (updateNewPage) {
        setTimeout(() => {
            editor.custom.coreModule.updatePage(newPage);
        }, 0);
    }
    return newPage;
}

export function getBrailleView(page) {
    return page?.querySelector('editor-braille-view');
}

/**
 * @param editor
 * @returns {HTMLElement}
 */
export function insertPageBreak(editor) {
    editor.undoManager.add();
    const br = document.createElement('br');
    replaceSelectedNodes(editor, [br]);
    const topNode = getRootNodeInPage(br);

    let walk = topNode.nextSibling;
    let toMove = [];
    while (walk) {
        toMove.push(walk);
        walk = walk.nextSibling;
    }
    removeNextSiblingChain(br, topNode.parentElement);

    let newPage = insertBlankPage(editor);
    for (let node of toMove) {
        newPage.append(node);
    }
    if (newPage.childNodes[0].nodeType === Node.TEXT_NODE) {
        editor.selection.setCursorLocation(newPage.childNodes[0], 0);
    } else {
        editor.selection.setCursorLocation(newPage, 0);
    }
    // braille view was moved to new page, back to the original
    const brailleView = getBrailleView(newPage);
    if (brailleView) {
        newPage.previousSibling.appendChild(brailleView);
    }
    return newPage;
}

/**
 * @param editor {EditorCustom}
 */
export function addParagraphBreakAboveSel(editor) {
    const node = getClosestEditorElement(editor.selection.getNode());
    editor.undoManager.transact(() => {
        const br = document.createElement('br');
        node.before(br);
        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = {
            caretPosition: getCaretPosition(editor),
        };
        editor.fire('pageDataChanged', pageDataChangedEvent);
    });
}

/**
 * @param editor {EditorCustom}
 */
export function addParagraphBreakBellowSel(editor) {
    let node = getClosestEditorElement(editor.selection.getNode());
    editor.undoManager.transact(() => {
        // noinspection JSUnresolvedReference
        if (
            node.nextElementSibling?.tagName === 'BR' &&
            node.nextElementSibling?.style?.display === 'none'
        ) {
            node = node.nextElementSibling;
        }
        /**
         * @type {Node}
         */
        const br = document.createElement('br');
        node.after(br);
        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = {
            caretPosition: getCaretPosition(editor),
        };
        editor.fire('pageDataChanged', pageDataChangedEvent);
    });
}

/**
 *
 * @param editor {EditorCustom}
 * @returns {HTMLElement}
 */
export function createNewPage(editor) {
    return editor.dom.create('editor-page', {
        'data-document-version': `${DOCUMENT_VERSION}`,
        'data-needs-update': 'true',
    });
}

/**
 * @param editor {EditorCustom}
 * @param page {Node}
 * @returns {boolean}
 */
export function removeEmptyPage(editor, page) {
    if (!isEditorPage(page)) return false;
    if (!pageHasText(page) && getLineCount(page) <= 2) {
        let selectPage;
        let selectPath;
        if (page.nextSibling) {
            selectPage = page.nextSibling;
            selectPath = [];
        } else if (page.previousSibling) {
            selectPage = page.previousSibling;
            selectPath = scanCaretPath(selectPage);
            selectPath.pop();
        }
        if (selectPage) {
            editor.undoManager.transact(() => {
                uncachePage(editor, page);
                uncachePage(editor, selectPage);
                const pageIdx = getPages(editor).indexOf(page);
                page.remove();
                editor.fire('pageRemoved', {
                    page: page,
                    pageIdx: pageIdx,
                });
                setCaretPosition(editor, selectPage, selectPath);
                setTimeout(() => {
                    updateScroll(editor);
                }, 100);
            });
            editor.custom.hasChange();
            return true;
        }
    }
    return false;
}

/**
 * @param page {HTMLElement | Node}
 */
export function getPageTermination(page) {
    let walk = page.lastElementChild;
    while (walk && isEditorBrailleView(walk)) {
        walk = walk.previousElementSibling;
    }
    if (walk && walk.tagName === 'BR') {
        return walk;
    }
    return null;
}

/**
 * All pages must have a BR in the end of page to avoid bizarres behaviors with cursor
 * @param editor {EditorCustom}
 * @param page {HTMLElement | Node}
 * @returns {boolean}
 */
export function fixPageTermination(editor, page) {
    let termination = getPageTermination(page);
    if (!termination) {
        termination = document.createElement('br');
        page.appendChild(termination);
    }
}

/**
 * @param page {HTMLElement}
 * @param brailleView {HTMLElement | null}
 */
export function appendBrailleView(page, brailleView) {
    if (brailleView) {
        page.append(brailleView);
    }
}

/**
 * @param page {HTMLElement | Node}
 * @param content {HTMLElement | Node}
 */
export function replacePageContents(page, content) {
    const brailleView = getBrailleView(page);
    page.innerText = '';
    page.appendChild(content);
    appendBrailleView(page, brailleView);
}

/**
/**
 * @param page {HTMLElement | DocumentFragment}
 * @param allLineBreaks {boolean}
 * @returns {[HTMLElement[]]}
 */
export function getPageLineBreaks(page, allLineBreaks) {
    let paragraphs = [];
    let paragraph = [];
    for (let child of page.childNodes) {
        if (isEditorBrailleView(child)) continue;

        const lineBreak =
            isEditorElementParagraphBreak(child) ||
            isInnerContextElement(child);

        if (child?.tagName === 'BR' || (allLineBreaks && lineBreak)) {
            paragraph.push(child);
            // maybe will be other elements that break a line
            paragraphs.push(paragraph);
            paragraph = [];
            continue;
        }
        paragraph.push(child);
    }
    if (paragraph.length && !paragraphs.includes(paragraph)) {
        paragraphs.push(paragraph);
    }
    return paragraphs;
}

/**
 * @param page {HTMLElement | DocumentFragment}
 * @returns {[HTMLElement[]]}
 */
export function getPageParagraphs(page) {
    return getPageLineBreaks(page, false);
}

/**
 * @param editor {EditorCustom}
 * @param page {number}
 * @return {number}
 */
export function goToPage(editor, page) {
    const pages = getPages(editor);
    if (page < 0) {
        page = 0;
    } else if (page >= pages.length) {
        page = pages.length - 1;
    }
    const targetPage = pages[page];
    setTimeout(() => {
        const htmlElement = editor.getBody().parentElement;
        const top = targetPage.offsetTop * editor.custom.zoom;
        htmlElement.scrollTo({
            behavior: 'smooth',
            top,
        });
    }, 0);
    setTimeout(() => {
        highlightPage(targetPage);
    }, 300);
    return page;
}

/**
 * @param page {HTMLElement}
 */
export function highlightPage(page) {
    page.classList.add('highlight');
    setTimeout(() => {
        page.classList.remove('highlight');
    }, 500);
}
