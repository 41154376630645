import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { getVersion } from './version';
import { BrowserTracing } from '@sentry/react';

const publicUrl = process.env.PUBLIC_URL ?? 'http://localhost:3000';
if (process.env.REACT_APP_SENTRY_DISABLED !== 'true') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [new BrowserTracing()],
        release: getVersion(),
        environment: new URL(publicUrl).hostname,
    });
}

/**
 * @type {Element}
 */
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const main = (
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
if (process.env.REACT_APP_RECAPTCHA_KEY) {
    root.render(
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY?.toString()}
            useRecaptchaNet
            scriptProps={{
                async: true,
                defer: true,
                appendTo: 'body',
            }}
        >
            {main}
        </GoogleReCaptchaProvider>,
    );
} else {
    root.render(main);
}
export { EDITOR_ELEMENTS_MAP } from './edit-document/editor-mods/modules/core/editor-element/Instances';
